#title {
    color: var(--brand-idwal-blue, #28235B);

    /* H2 */
    font-family: Open Sans, sans-serif;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 1.7875rem */
    letter-spacing: 0.0125rem;
}

#subtitle {
    color: var(--text-grey-mid-2, #777);

    /* body small */
    font-family: Open Sans, sans-serif;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1.05625rem */
    margin-top: 1rem;
    margin-bottom: -12px;
}

.bottomRow {
    border-bottom: 1px solid var(--web-grey-borders, #DDD);
    margin: 0!important;
}

.showAllFiltersContainer {
    border-top: 1px solid var(--web-grey-borders, #DDD);
    margin: 0!important;
}

.showAllFiltersTitle {
    padding: 0.25rem 0 0 0;
}

.allText {
    padding: 0.25rem 0 0 0;
    margin-right: 0.5rem;
}

.versus {
    padding: 0.25rem 1rem;
    margin-right: 0.5rem;
}

.showAllFiltersPill {
    display: flex;
    padding: 0.25rem 1rem;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    border-radius: 3.125rem;
    border: 1px solid var(--brand-blue-tint-30, #68658C);
    background: var(--web-white, #FFF);
}

.leftHandPill {
    color: #68658C;
}

.rightHandPill {
    border: 1px solid var(--text-grey-mid-2, #777)!important;
    background: var(--web-grey-lightest, #F8F8F8)!important;
    color: #777777;
}

.no-filters-button {
    color: #BBBFC3!important;
    background-color: transparent!important;
    border-color: transparent;
    width: 116px;
    cursor: auto;
    border-radius: 0.375rem !important;
    border: 1px solid var(--web-grey-borders, #DDD) !important;
    font-weight: 600;
}