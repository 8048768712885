.defect-graph-title {
    color: #555;
    font-family: sans-serif, "Open Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.defect-graph-legend-title {
    color: #555;
    font-family: sans-serif, "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-left: 6px;
}

.defect-graph-xaxis-title {
    color: #555;
    text-align: center;
    font-family: sans-serif, "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.defects-graph .spikeline { 
    display: none;
}

.defects-graph .legend .bg {
    stroke-linejoin:round;
    stroke-width:6px !important;
}
