.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.p-datatable-thead {
  z-index: 2;
}

.risk-drop-down {
  margin-top: -20px;
  position: absolute;
  z-index: 200;
  overflow: visible;
}

#marketing-container {
  margin-bottom: 20rem;
}


.p-frozen-column {
  z-index: 1;
}

.trending-tool-tip {
  margin-left : 10px;
}

.trending-up-arrow {
  color: #53A440;
  font-size: 1rem;
  margin-right: 4px;
  height: 40vmin;
  pointer-events: none;

}

.trending-down-arrow {
  color: #EC1E17;
  font-size: 1rem;
  margin-right: 4px;
  height: 40vmin;
  pointer-events: none;
}

.trending-arrow-equal {
  color: #68658C;
  margin-right: 6px;
  font-size: 1rem;
  height: 40vmin;
  pointer-events: none;
}

.trending-text {
  color : #555555;
  font-size: 1rem;
  font-weight: 400;
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.clickableLabel {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-danger, .text-danger p {
  color: #EC1E17;
}

.overdue-text {
  font-size: 0.857rem;
}

.font-weight-normal {
  font-weight: 400;
}

.p-inputnumber-input {
  width: 100%;
  border-radius: 6px 6px 6px 6px!important;
}

.p-datatable .p-datatable-header {
  padding: 0;
  padding-bottom: 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.625rem;
}

.clearButtonGreyBorder {
  border-radius: 0.375rem!important;
  border: 1px solid var(--web-grey-borders, #DDD)!important;
}

.content-width {
  width: calc(100% - 7rem);
}

@media(max-width: 960px) {
  .content-width {
    width: calc(100% - 4.6rem);
  }
}

.table-actions button img {
  display:block;
}

.p-datatable .p-column-header-content {
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1410px) {
  .global-filter-column {
    width: 12rem!important;
  }

  .global-filter-column-content {
    width: 11rem!important;
  }
}


@media  screen and (min-width: 1655px) {
  .new-comparison-div {
    text-align: right;
  }
}

.p-column-filter {
  text-overflow: ellipsis;
}

.p-tooltip .p-tooltip-text {
  white-space: nowrap!important;
}

.p-tooltip-arrow{
  left: 1px!important;
}

.p-tabview .p-tabview-panels {
  padding: 2rem;
  box-shadow: none!important;
}

.inspection-due-cell, .inspection-date-cell {
  display: flex;
  flex-direction: column;
}

.inspection-due-location {
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  padding-right: 0.5rem;
  overflow-wrap: anywhere;
}

.secondary-outlined-button {
  border-radius: 0.375rem;
  border: 1px solid #DDD !important;
  box-shadow: none!important;
  color: #009EB2;
}

footer > .max-width-wrapper {
  max-width: none!important;
}

@media(max-width: 992px) {
  footer > .max-width-wrapper {
    padding-left: 5rem!important;
  }
}

.p-datatable-emptymessage {
  display: table-row!important; /* For some reason idwal-theme.css sets this to display: none. Overriding here */
}

.request-inspection {
  float: right;
}

.close-defects div.p-dialog-title {
  color: #28235B;
}

.close-defects-text {
  color: #333333;
  font-size: 1rem;
}

.close-defects-button {
  padding: 14px 20px;
}

.shared-defects-table-container {
  margin-bottom: 3rem !important;
}

.shared-defects-table-container .p-frozen-column {
  z-index: 1;
}

.shared-defects-table-container > div > div.p-datatable-wrapper {
  max-height: calc(100vh - 600px);
}

.subgrade-table-container > div > div.p-datatable-wrapper {
  max-height: calc(100vh - 360px);
}

.shared-defects-table-container thead.p-datatable-thead {
  z-index: 2;
}

.p-datatable-loading-overlay.p-component-overlay {
  z-index: 3;
}

.clear-button {
  width: 180px;
  height: 55px;
  border: 1px solid #ddd;
  background-color: white;
  color: #009eb2;
  padding: 14px 28px;
  font-family: 'Open Sans', serif;
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  border-radius: 10px;
}

.p-float-label input:focus ~ label, .p-float-label .p-inputwrapper-focus ~ label {
  color: #333;
  top: -23px!important;
  background: transparent;
}

.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
  background-color: transparent;
  top: -23px!important;
  font-size: 0.8331rem;
}

.p-datatable-header {
  background: #FFFFFF !important;
}

.delete-column-button > .p-button-icon {
  font-size: 1rem!important;
}

.delete-column-button {
  min-height: 1rem!important;
  padding: unset!important;
}

.legendtext {
  font-size: 1rem!important;
}

.toggle-stat-tiles {
  color: #DDD!important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: #f8f8fa;
}

.p-chips-multiple-container {
  width: 100%;
}

.shareDefectsModal .p-chips .p-chips-multiple-container {
  border: none;
  background: none;
  padding: 0;
}

.shareDefectsModal .p-fieldset, .p-fieldset .p-fieldset-legend {
  background: #F8F9FA!important;
}

.shareDefectsModal .p-fieldset .p-fieldset-legend {
  border: 1px solid #e0e0e0!important;
}

.table-right-border {
    border-right-width: 1px !important;
}

.table-no-bottom-border {
    border-bottom-width: 0 !important;
}

.table-small-column {
    min-width: 120px;
}

.table-large-column {
    min-width: 260px;
}

/* Style overrirdes for plotly notification when downloading chart images */
.plotly-notifier {
  right: 20px;
  bottom: 20px;
  top: unset!important;
  max-width: 250px!important;
  width: 250px!important;
}

.notifier-note {
  margin-top: 1rem!important;
  background-color: #dcecd8!important;
  border: 1px solid #a8cb9f!important;
  border-radius: 3px!important;
  width: 100%!important;
}

.notifier-close {
  color: #7fba70!important;
  margin-top: 10px!important;
  margin-left: 1rem!important;
}

/* Style overrirdes for the deviation graph toolbar camera */
.fleet-deviation-tab-content .modebar-group {
  background-color: transparent;
}

.fleet-deviation-tab-content .main-svg {
  padding-top: 15px!important;
}