.advanced-filter-range {
    color : #777;
    font-size: 1rem;
    line-height: 150%;
    padding-bottom: 16px;
}
  
.advanced-filter-range-text {
    color : #333;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 16px;

}
  
.advanced-filter-range-slider {
    padding-left: 10px;
    color : #333;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
}

.advanced-filter-range-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}

.advanced-filter-range-number-box-wrapper {
    flex: 0 2 50%
}

.advanced-filter-range-number-box-wrapper > span {
    width: 100%
}

.advanced-filter-range-seperator-text {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 5%;
    align-self: center;
    text-align: center;
    align-items: center;
    flex: 0 1 5%;
}