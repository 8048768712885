.stats-tile-container {
    border-radius: 10px;
    margin: 1.5rem 0 1.5rem 0;
}

.stats-tile-panel > .p-toggleable-content > .p-panel-content {
    background: unset!important;
    padding: unset!important;
}

.stats-tile-panel {
    background: unset!important;
}