.reorder-table > .p-datatable-footer {
    padding-top: 1rem!important;
    padding-bottom: 0!important;
    border: none!important;
}

.reorder-table > .p-datatable-wrapper > table > tbody > tr > td {
    flex: unset!important;    
}

.row-reorder > .p-datatable-reorderablerow-handle {
    color: #28235B;
    font-size: 1rem;
}