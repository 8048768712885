.warning-message{
    border-radius: 6px;
    border: 1px solid var(--grade-orange, #F8A111);
    background: var(--grade-orange-20, #FEECCF);
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-bottom: 12px;
}

.warning-message__icon {
    margin-right: 0.5rem;
    color: #F8A111;
    cursor: pointer;
}