.filterSelectionContainer__title-container {
    border-radius: 12px 12px 0px 0px;
    opacity: 0.87;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    width: 100%;
    box-sizing: border-box;
    max-height: 500px;
}

.filterSelectionContainer__title-container--foreground {
    border-top: 1px solid var(--brand-idwal-blue, #28235B);
    border-right: 1px solid var(--brand-idwal-blue, #28235B);
    border-left: 1px solid var(--brand-idwal-blue, #28235B);
    background: #57537A;
    color: white;
    padding: 13px 24px;
}

.filterSelectionContainer__title-container--background-market {
    border-top: 1px solid var(--web-grey-light, #BBBFC3);
    border-right: 1px solid var(--web-grey-light, #BBBFC3);
    border-left: 1px solid var(--web-grey-light, #BBBFC3);
    background-color: var(--web-grey-lightest, #f8f8f8);
}

.filterSelectionContainer__title-container--background-fleet {
    border-top: 1px solid var(--web-grey-light, #BBBFC3);
    border-right: 1px solid var(--web-grey-light, #BBBFC3);
    border-left: 1px solid var(--web-grey-light, #BBBFC3);
    background-color: var(--web-grey-lightest, #dddddd);
}

.filterSelectionContainer__title-icon {
    display: flex;
    align-items: center;
}

.filterSelectionContainer__disabled {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--text-grey-mid-2, #777);
    text-align: center;
    text-overflow: ellipsis;
    padding: 12px 24px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    background-color: #f8f8f8;
    border-radius: 0px 0px 12px 12px;
}

.filterSelectionContainer__title-text--foreground {
    color: #FFF;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.filterSelectionContainer__title-text--background-market {
    color: var(--text-grey-mid-2, #777);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.filterSelectionContainer__title-text--background-fleet {
    color: var(--text-grey-mid-2, #777);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.filterSelectionContainer__content-container--foreground {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--brand-idwal-blue, #28235B);
    border-right: 1px solid var(--brand-idwal-blue, #28235B);
    border-left: 1px solid var(--brand-idwal-blue, #28235B);
    border-bottom: 1px solid var(--brand-idwal-blue, #28235B);
    border-radius: 0px 0px 12px 12px;
}

.filterSelectionContainer__content-container--background-market {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-right: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-left: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-bottom: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-radius: 0px 0px 12px 12px;
}

.filterSelectionContainer__content-container--background-fleet {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-right: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-left: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-bottom: 1px solid var(--brand-idwal-blue, #BBBFC3);
    border-radius: 0px 0px 12px 12px;
}