#vessel-view-sidebar {
    width: 50rem;
}

@media screen and (min-width: 576px) {
    #vessel-view-right-header {
        background: rgba(244, 248, 251, 0.80);
        backdrop-filter: blur(3px);
    }
}

#vessel-view-right-column {
    background: rgba(244, 248, 251, 0.80);
    backdrop-filter: blur(3px);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 0px;
    height: calc(100% - 100px) !important; /* The 100px here is the height of the header element in the vessel view sidebar. If that height changes, this 100px value will need to change accordingly. */
}

#vesselView__particularsSkeleton {
    height: 70rem !important;
}

.vesselView__imageSkeleton {
    height: 190px !important;
}

.vesselView__particularsTitle {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    color: #777;
}

.vesselView__particularsValue {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    color: #333;
}

.vessel-view-right-column::-webkit-scrollbar {
    display: none;
}

#vessel-view-left-column {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-left: 1.5rem!important;
    padding-right: 1.5rem!important;
    padding-top: 0px;
    margin-top: 0px!important;
}

.vessel-view-left-column::-webkit-scrollbar {
    display: none;
}

#vessel-view-container {
    margin: 0;
    padding: 0;
}

/*Only exists to demo scrolling. Should be deleted once implementation is complete*/
#scroll-test {
    top: 1800px;
    position: relative;
}

#vessel-view-close-button {
    width: 3.25rem;
    height: 3.25rem;
    margin: -0.5rem 1rem 0 0;
    color: #009eb2;
    border: 0 none;
    background: transparent;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

#vessel-view-close-button:hover {
    background-color: rgba(51, 51, 51, 0.05);
}

.vesselView__vesselName {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 1.7875rem */
    letter-spacing: 0.0125rem;
    margin-top: 0.75rem;
    color: #333333;
}

.vesselView__vesselImo {
    font-size: 1rem;
    color: var(--Text-GREY-Mid-2, #777);
}

.vesselView__labelTitle {
    font-size: 0.8125rem;
    color: var(--Text-GREY-Mid-2, #777);
}

.vesselView__labelText {
    font-size: 1rem;
    color: var(--Text-GREY-Dark, #333);
}

.vesselView__labelText--danger {
    font-size: 1rem;
    color: var(--Text-GREY-Dark, #EC1E17);
}

.vesselView__labelSeperator {
    padding-bottom: 0.75rem;
}

.vesselView__gradeContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}

.vessel-view-header {
    padding-top: 20px!important;
    padding-left: 24px!important;
    padding-right: 0px!important;
    padding-bottom: 0px!important;
}

@media (max-width: 576px) {
    .vesselView__gradeContainer {
        margin-top: 1rem
    }

    .vessel-view-header {
        padding-bottom: 20px!important;
    }
}

.vessel-view__large-logo-container {
    margin-left: -20px!important;
}

.vessel-view__image {
    border-radius: 10px;
    width: 100%;
}

.vessel-view__logo {
    height: 32px;
}

.vessel-view__noVesselImage {
    width: 100%;
    border-radius: 6px;
}