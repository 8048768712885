.p-hidden-space {
    width: 0px;
    display:none;
}

.p-editable-column {
    height: auto !important;
}

.pi-search {
    color:#28235B !important;
}

.p-datatable-header {
    border: 0 !important;
}

.view-report-link {
    text-decoration: none;
    color: inherit;
}

.defect-priority-high {
    color: #EF5A14;
}

.defect-priority-medium {
    color: #F8A111;
}

.defect-image-icon {
    color: #009EB2;
}

.defect-status-icon {
    color: #009EB2;
}

.total-defect-btn {
    border: none;
    background: transparent;
}

.defect-priority-link-btn {
    border: none;
    background: transparent;
    display: flex;
}

.defect-status-link-btn {
    border: none;
    background: transparent;
    border-radius: 4px;
    height: 30px;
    width: 125px;
}

.defect-status-closed {
    background: #DDEDD9;
}

.defect-status-rectified {
    background: #FEECCF;
}

.defect-status-in-progress {
    background: #E7F6F7;
}

.defect-status-open {
    background: #FBD2D1;
}

div.p-paginator {
  justify-content: center;
}

.priority-icon-size {
    height: 24px;
    width: 24px;
}

.priority-icon-size-small {
    height: 16px;
    width: 16px;
}

.line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: '';
}

.read-more-btn {
    width: fit-content;
    color: #008094;
}

.read-more-btn:hover {
    color: #009EB2;
}

.defect-note:hover {
    border-color: #B0E2E4;
    cursor: pointer;
}

.p-dropdown-trigger-icon {
    font-size: 0.875rem;
}

.defect-btn-container {
    display: flex;
    justify-content: center;
}

.defect-btn {
    margin: 10px;
}

.shared-defect-btn {
    margin: 0px;
}

.defect-radio-btn-container {
    padding: 1rem;
}

.defects_table > div > div.p-datatable-wrapper {
    max-height: calc(100vh - 650px);
  }

@media screen and (max-width: 576px) {
    .shared-defect-btn {
        margin-top: 10px;
    }
  }