.drop-down-text {
    flex: max-content;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #555555;
    text-align: left;
}

li.p-dropdown-item.p-highlight{
    background-color: rgba(239, 249, 250, 0.5) !important;
}
