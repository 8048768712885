.screening-table-flag {
    height: 16px;
    width: 22.667px;
    border: 1px solid lightgray;
}

.screening-table th:nth-child(5) {
    border-right-width: 1px !important;
}

.screening-table th:nth-child(8) {
    border-right-width: 1px !important;
}

.screening-table th:nth-child(10) {
    border-right-width: 1px !important;
}

.screening-remove-ship .p-menuitem-text {
    color: #EC1E17 !important;
}

.screening-actions .p-menuitem-text {
    color: #555555 !important;
    white-space: nowrap;
}

.screening-grade-info {
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    width: 600px;
}

.screening-grade-info::before {
    border: none;
}

.screening-grade-info::after {
    border: none;
}

.screening-grade-image {
    height: 72px;
}

.screening-grade-summary {
    background-color: #F8F8F8;
    border-radius: 16px;
    white-space: pre-wrap;
}

.screening-grade-name {
    color: #333
}