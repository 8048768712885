.table-container {
    background: #FFFFFF;
    box-shadow: 0px 13px 11px 12px rgba(117, 166, 203, 0.03);
    border-radius: 10px;
    padding: 2rem;
}

.p-hidden-space {
    width: 0px;
    display:none;
}

.pi-search {
    color:#28235B !important;
}

.p-datatable-header {
    border: 0 !important;
}


.home-table > div > div.p-datatable-wrapper {
    max-height: calc(100vh - 230px);
}
