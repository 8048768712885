@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext');

.js-plotly-plot text.name {
    display:none;
}

.js-plotly-plot .hovertext path {
    scale: 1.2;
    translate: 5px;
}

.js-plotly-plot .hovertext text[text-anchor="start"] {
    translate: 15px;
}

.js-plotly-plot .hovertext text[text-anchor="end"] + path {
    translate: -5px;
}

.js-plotly-plot .hovertext text[text-anchor="end"] {
    translate: -15px;
}

.hovertext path {
    stroke-linejoin:round;
    stroke-width:6px !important;
    stroke: #555 !important;
}

.graph-container {
    background: #FFFFFF;
    border-radius: 10px;
    margin: 2rem 2rem 1rem;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background: #00c7cc !important;
    border-radius: 3px;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button span {
    color:white !important;
}

.graph-container .pi-filter-icon {
    font-size: 1.5rem;
}

.js-plotly-plot {
    display: block !important;
}

.plot-container.plotly {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60% !important; /*this is the aspect ratio*/
}

.plot-container .svg-container {width: 100% !important; height: 100% !important}

.js-plotly-plot .plotly .main-svg {
    overflow: visible;
}

.scatterpts {
    opacity: 1 !important;
}

@media (min-width: 1600px) {
    .plot-container.plotly {
        padding-bottom: 50% !important;
    }
}

@media (min-width: 2400px) {
    .plot-container.plotly {
        padding-bottom: 40% !important;
    }
}

@media (max-width: 992px) {
    .plot-container.plotly {
        padding-bottom: 100% !important;
    }
}

@media (max-width: 767px) {
    .plot-container.plotly {
        padding-bottom: 200% !important;
    }
}

.tab-container {
    background:transparent;
}

.p-tabview .p-tabview-nav {
    background: transparent !important;
}

.p-inputswitch-slider {
    z-index: 1;
}