.psc-table-link-btn {
    border: none;
    background: transparent;
    display: flex;
    margin: auto;
}

.psc-detained-yes {
    background-color: #FBD2D1;
    border-radius: 6px;
    color: #B90000;
    padding: 4px 8px;
}

.psc-defect-code {
    background-color: #FFF;
    border-radius: 50px;
    border: 1px solid #DDD;
    padding: 4px 16px;
    font-weight: 600;
    color: #333;
}

.psc-table-count {
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.psc-aggregate-label {
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    white-space: nowrap;
}

.psc-aggregate-count {
    display: flex;
    padding: 8px;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #DDD;
    background: #FFF;
    width: unset;
}

.psc-not-applicable-label {
    color: #777777
}