.defect-insight-graph-title {
    color: #555;
    font-family: sans-serif, "Open Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.defect-insight-graph { 
    height: 500px;
}

.defect-insight-graph .hovertext path { 
    stroke-linejoin:round;
    stroke-width:6px !important;
    stroke: #1C1940 !important;
}

.defect-insight-graph .legendtext {
    font-size: 12px !important;
}
