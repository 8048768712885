.defects-summary-large-column {
    min-width: 12rem;
}

.defects-summary-small-column {
    min-width: 7rem;
}

.defects-summary-right-border {
    border-right-width: 1px !important;
}

.defects-summary-no-bottom-border {
    border-bottom-width: 0 !important;
}