.vessel-view-accordion-panel > .p-toggleable-content > .p-panel-content {
    padding: 1rem!important;
}

.vessel-view-accordion__title {
    margin-bottom: 0.22rem;
}

.accordion-button {
    min-width: unset!important;
    min-height: unset!important;
    height: 33px!important;
    width: 33px!important;
}