.tab-container {
    background:transparent;
}
  
/* Remove the line at the top of the first label */
svg > g.cartesianlayer > g > g.yaxislayer-above > path:first-of-type {
  display: none;
}
  
/* Remove the line at the bottom of the last label */
svg > g.cartesianlayer > g > g.yaxislayer-above > path:last-of-type {
  display: none;
}

.subgrade-vessel-dropdown {
  width: 23rem;
}

.view-all-areas-button {
  padding-left: 12rem;
}