.asset-gallery-item {
    height: 204px;
    cursor: pointer;
}

.asset-gallery-file-item {
    border: 1px #DDDDDD solid;
    width: 100%;
}

.asset-gallery-item-name {
    font-size: 0.8125rem;
    color: #777777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px; 
}

.asset-gallery-item-icon {
    color: #777777;
    font-size: 3.5rem;
}

.asset-gallery-image {
    height: auto;
    width: 100%; 
    object-fit: cover;
}

.asset-gallery-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 8px;
    height: 48px;
    width: calc(100% - 1rem);
    position: absolute;
}

.asset-gallery-overlay-icon {
    color: white;
}

.asset-gallery-overlay-icon:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}