.defect-rectification-activity-text {
    color: #777;
    line-height: 150%;
}

.defect-rectification-activity-subtext {
    color: #333;
    line-height: 150%;
}

.defect-rectification-activity .p-timeline-event-opposite {
    display: none;
}


.defect-rectification-activity-marker {
    border: 2px solid #06b6d4;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
}

.defect-rectification-activity .p-timeline-event-connector {
    background-color: #e5e7eb;
}