.filter-list-item {
    display: flex;
    padding: 14px 8px;
    gap: 6px;
    align-self: stretch;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;

    &&:hover {
        background-color: #f6f6f6
    }

    &&:hover > .filter-list-item__count > .filter-list-item__vesselCount {
        background-color: white;
        color: black
    }

    &&:hover > .filter-list-item__count > .filter-list-item__marketCount {
        background-color: white;
        color: black
    }

    .filter-list-item__count > .filter-list-item__vesselCount {
        background-color: #f8f8f8;
        color: black
    }

    .filter-list-item__count > .filter-list-item__marketCount {
        background-color: #f8f8f8;
        color: black
    }
}

.filter-list-item--selected {
    display: flex;
    min-width: 200px;
    align-items: center;
    padding: 14px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--brand-light-sky-20, #EFF9FA);
    color: #221d4e;
    &&:hover {
        background-color: #eff9fa;
    }
}

.filter-list-item__title {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.filter-list-item__count {
    margin-left: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.filter-list-item__vesselCount {
    background-color: #504e79;
    display: flex;
    padding: 1px 7px 2px 7px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.75rem;
    width: 21px; /* Set the width */
    height: 21px; /* Set the height */
    margin-right: 6px;
}

.filter-list-item__marketCount {
    display: flex;
    padding: 1px 7px 2px 7px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: var(--web-grey-light, #BBBFC3);
    color: white;
    font-size: 0.75rem;
    width: 21px; /* Set the width */
    height: 21px; /* Set the height */
}

.filter-list-item__arrow-container {
    min-width: 20px;
    align-items: center;
    display: flex;
    margin-left: 8px;
}