.list-picker .p-button {
    padding: 14px 20px;
}

.list-picker .p-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.list-picker .p-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.list-picker .p-button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.list-picker-loading {
    opacity: 0.75;
}