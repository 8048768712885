.menu-button-secondary {
    border-radius: 4px;
    border: 1px solid var(--Web-Grey-Borders, #DDD);
    color: var(--Text-Button-Text, #009EB2);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    background-color: white;
}

.menu-button-secondary:hover {
    background-color: #d8d8d8!important;
    color: #008495!important;
}