.ai-toggle-switch {
    background-color: transparent!important;
}

.ai-overlay-body-text {
    font-size: 1rem;
    font-weight: 600;
    color: #28235B;
}

.ai-contact-us:link, .ai-contact-us:visited {
    color: #008094;
}

.ai-contact-us:hover, .ai-contact-us:active {
    color: #009EB2;
}
