.new-column-modal-label {
    width: 9.5rem;
    margin-right: 1rem;
}


.new-column-modal-row {
    margin-top: 2rem;
}

.error-message {
    margin-left: 10.5rem;
}
