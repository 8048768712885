.filterTypeToggle {
    display: flex;
    width: var(--Select-md, 300px);
    
    padding: 12px 0px 12px 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
}

.filterTypeToggle__text {
    color: var(--text-grey-mid, #555);
    /* body small */
    font-family: Open Sans, sans-serif;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.filterTypeToggle__checked {
    font-weight: 600;
}

.myFleetToggle {
    margin-right: -8px;
}