.inspection-report-card-details {
    margin-left: 3.5rem;
}

.inspection-report-card-detail {
    color: #777777;
    font-size: 0.8125rem;
}

.inspection-details-type {
    color: #333333;
}