.filterDialog__content-container {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    overflow-y: scroll;
    height: 100%;
    flex-basis: 25%;
}

.filterDialog__fleet-filter-container {
    flex-basis: 37.5%;
}

.filterDialog__market-filter-container {
    flex-basis: 37.5%;
}
.advancedFilterWrapper > .p-dialog-content {
    overflow-y: hidden!important;
}


.filterDialog__selection-container {
    min-height: 505px;
    height: 505px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.filterDialog__filter-content-container {
    min-height: 450px;
    height: 450px;  
    overflow-y: scroll;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media (max-height: 826px) {
    .filterDialog__filter-content-container {
        min-height: 250px;
        height: 250px;  
    }

    .filterDialog__selection-container {
        min-height: 305px;
        height: 305px;
    }
}

@media (max-height: 545px) {
    .filterDialog__filter-content-container {
        min-height: 100px;
        height: 100px;
    }

    .filterDialog__selection-container {
        min-height: 155px;
        height: 155px;
    }
}

.filterDialog__filter-content-container::-webkit-scrollbar {
    display: none;
}

.filterDialog__footer {
    display: flex;
    justify-content: center;
    justify-content: space-between;
}

.filterDialog__filter-container {
    margin: 1rem 1.5rem 1rem 1.5rem;
}

.selection-message {
    font-size: 0.8125rem;
    color: var(--text-grey-mid-2, #777);

}

.filter-dialog-style {
    padding: 1.5rem 1.5rem 0rem 1.5rem!important;
}
