.p-fileupload .p-fileupload-content {
    padding: 0;
    margin: 0;
    padding-bottom: 100px;
}

.file-uploader-container {
    position: relative;
}

.drag-and-drop-message {
    position: absolute;
    bottom: 30px;
    padding: 2rem 1rem;
    pointer-events: none;
}

.p-fileupload .p-message-error {
    display: none;
}